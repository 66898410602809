export const securityText = `
# 情報セキュリティ基本方針

株式会社Opening Line（以下、当社）は、お客様からお預かりしたものを含む当社の情報資産を事故・災害・犯罪などの脅威から守り、お客様ならびに社会の信頼に応えるべく、以下の方針に基づき全社で情報セキュリティに取り組みます。

## 1.経営者の責任
当社は、経営者主導で組織的かつ継続的に情報セキュリティの改善・向上に努めます。

## 2.社内体制の整備
当社は、情報セキュリティの維持及び改善のために組織を設置し、情報セキュリティ対策を社内の正式な規則として定めます。

## 3.従業員の取組み
当社は、経営者主導で組織的かつ継続的に情報セキュリティの改善・向上に努めます。

## 4.法令及び契約上の要求事項の遵守]
当社は、情報セキュリティに関わる法令、規制、規範、契約上の義務を遵守するとともに、お客様の期待に応えます。

## 5.違反及び事故への対応
当社は、情報セキュリティに関わる法令違反、契約違反及び事故が発生した場合には適切に対処し、再発防止に努めます。

`