import * as React from "react"
import Seo from "../components/seo"
import { useRemark } from 'react-remark'
import styled from '@emotion/styled'
import Layout from "../components/layout"
import Title from '../components/feature/Contact/Title'
import { securityText } from "../datas/security"
import { Color } from "../util/Color"

const description = ''

const Privacy = () => {
  const [reactContent, setMarkdownSource] = useRemark();

  React.useEffect(() => {
    setMarkdownSource(securityText);
  }, []);
  return (
    <Layout>
      <Seo title="SecurityPolicy" description={description}/>
      <Title en="Security Policy" ja="情報セキュリティ基本方針" />
      <Content>
        {reactContent}
      <Right>
        <div>制定日:2020年4月1日</div>
        <div>株式会社Opening Line</div>
        <div>代表取締役 佐々木 亮一</div>
      </Right>
      </Content>
    </Layout>
    )
}

export default Privacy

const Content = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  '> *': {
    fontWeight: 400,
    fontFeatureSettings: '"palt" 1',
    fontFamily: 'Noto Sans JP',
    color: Color.black
  }
})

const Right = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'end',
  gap: '8px',
  width: '100%',
  marginTop: '40px'
})
